export const cardRoutes = [
    {
        path: "/groupBuying",
        name: "groupBuying",
        meta: {
            title: "线上团购",
            key: 'group-buying',
            pageApisPrm: import("@/views/business/groupBuying/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/groupBuying/Index.vue"),
    },
    {
        path: "/groupCardVerifyRecord",
        name: "groupCardVerifyRecord",
        meta: {
            title: "核销记录",
            key: 'group-card-verify-record',
            pageApisPrm: import("@/views/business/groupCardVerifyRecord/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () =>
            import("@/views/business/groupCardVerifyRecord/Index.vue"),
    },
    {
        path: "/givecoupons",
        name: "givecoupons",
        meta: {
            title: "赠送优惠券",
            key: 'give-coupons',
            pageApisPrm: import("@/views/couponManage/giveCoupon/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/couponManage/giveCoupon/Index.vue"),
    },
    {
        path: "/coupons",
        name: "coupons",
        meta: {
            title: "优惠券列表",
            key: 'coupons-set',
            pageApisPrm: import("@/views/couponManage/couponSet/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/couponManage/couponSet/Index.vue"),
    },
]