import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import {businessRoutes} from '@/router/business'
import {memberRoutes} from '@/router/member'
import {dataStatisticsRoutes} from '@/router/dataStatistics'
import {goodsManageRoutes} from '@/router/goodsManage'
import {orderRoutes} from '@/router/order'
import {cardRoutes} from '@/router/card'
import {coachManageRoutes} from '@/router/coachManage'
import {revenueReportRoutes} from '@/router/revenueReport'
import {accountManageRoutes} from '@/router/accountManage'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login'
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
        ...businessRoutes,
        ...memberRoutes,
        ...goodsManageRoutes,
        ...orderRoutes,
        ...cardRoutes,
        ...dataStatisticsRoutes,
        // ...couponsRoutes,
        ...coachManageRoutes,
        ...revenueReportRoutes,
        ...accountManageRoutes
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
