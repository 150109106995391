export const orderRoutes = [
    {
        path: "/orderlist",
        name: "orderlist",
        meta: {
            title: "订单记录",
            key: 'order-list',
            pageApisPrm: import("@/views/business/orderlist/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/orderlist/Index.vue"),
    },
]