 import store from "@/store";
let businessRoutes = [
    {
        path: "/business",
        name: "business",
        meta: {
            title: "台桌",
            key: 'business',
            pageApisPrm: import("@/views/business/business/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/business/business/Index.vue"),
    },
    // {
    //     path: "/members",
    //     name: "members",
    //     hidden:store.state.platformType===2,
    //     meta: {
    //         title: "会员管理",
    //         key: 'members',
    //         pageApisPrm: import("@/views/business/members/Index.vue").then(({ pageApiList }: any) => pageApiList)
    //     },
    //     component: () => import("@/views/business/members/Index.vue"),
    // },
    // {
    //     path: "/membershis",
    //     name: "membershis",
    //     hidden:store.state.platformType===2,
    //     meta: {
    //         title: "会员开通记录",
    //         key: 'membershis',
    //         pageApisPrm: import("@/views/business/membershis/Index.vue").then(({ pageApiList }: any) => pageApiList)
    //     },
    //     component: () => import("@/views/business/membershis/Index.vue"),
    // },
    //
    // {
    //     path: "/member_fee",
    //     name: "member_fee",
    //     meta: {
    //         title: "会员消费记录",
    //         key: 'member-fee',
    //         pageApisPrm: import("@/views/business/memberFee/Index.vue").then(({ pageApiList }: any) => pageApiList)
    //     },
    //     component: () =>
    //         import("@/views/business/memberFee/Index.vue"),
    // },
]
 // businessRoutes = businessRoutes.filter(item=>!item.hidden)
 export { businessRoutes }