import Vue from 'vue';
import App from './App.vue';

import store from './store';
// import '@/common/setHeadInfo';
import router from './router';
import './registerServiceWorker';

import '@/css/cssrest.styl';
import '@/css/global.less';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/index.css';

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import "@/assets/font/font.css";

// 图片预览器
Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 99999
  }
})

// 引入echarts
// import echarts from 'echarts';
import * as echarts from 'echarts';

// import { echarts } from "@/plugins/echarts.js";


Vue.prototype.$echarts = echarts;

//
// import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)


// highcharts
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)
Vue.prototype.$highcharts = highcharts;

import scroll from 'vue-seamless-scroll';
Vue.use(scroll, { componentName: 'scroll-seamless' });

Vue.use(VXETable);

Vue.use(ElementUI);

// import './assets/font/font.css';

//MD5
// import md5 from 'js-md5';
// Vue.prototype.$md5 = md5;


//XLSX
import XLSX from 'xlsx';

//rsa
import JsEncrypt from 'jsencrypt'
Vue.prototype.$jsEncrypt = JsEncrypt

// //vue-amap
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   key: '95fa72137f4263f8e64ae01f766ad09c',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   // 默认高德 sdk 版本为 1.4.4
//   v: '1.4.4'
// });

import axios, { AxiosStatic } from 'axios';

const platformType:number = store.state.platformType
const is_test:boolean = location.host.indexOf('test')>-1
Vue.use((v) => {
  // v.prototype.$video = Video;
  // axios默认配置
  axios.defaults.timeout = 10000;   // 超时时间
  axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

  v.prototype.$axios = axios;
  v.prototype.$getResourceCodes = function (key: string) {
    // return this.$store.state.resourceCodes.includes(key);
    return true;
  };
});

declare module 'Vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
    $getResourceCodes: Function;
    $video: any;
  }
}

// 使用钩子函数对路由进行权限跳转
// router.beforeEach((to, from, next) => {
//   document.title = `${to.meta.title} | 云上畜牧`;
//   const role = localStorage.getItem('id');
//   if (!role && to.path !== '/login') {
//     next('/login');
//   } else {
//     // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
//     if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
//       Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
//         confirmButtonText: '确定',
//       });
//     } else {
//       next();
//     }
//   }
// });
// end
document.title = '众享计费系统'

import Print from 'vue-print-nb'
Vue.use(Print);
Vue.config.productionTip = true;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
