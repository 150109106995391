export const dataStatisticsRoutes = [
    {
        path: "/dataStatistics",
        name: "dataStatistics",
        meta: {
            title: "数据统计",
            key: 'data-statistics',
            pageApisPrm: import("@/views/dataStatistics/Index.vue").then(({ pageApiList }: any) => pageApiList)
        },
        component: () => import("@/views/dataStatistics/Index.vue"),
    },
    // {
    //     path: "/report",
    //     name: "report",
    //     meta: {
    //         title: "营收报表",
    //         key: 'zhongxiang-report',
    //         pageApisPrm: import("@/views/revenueReport/revenueReport/Index.vue").then(({ pageApiList }: any) => pageApiList)
    //     },
    //     component: () => import("@/views/revenueReport/revenueReport/Index.vue"),
    // },
]